<!-- 团长发布页面 -->
<template>
  <div class="colonelPublishing">
    <div class="head-area clr">
      <div class="fl clr left-search">
        <div class="fl search-item">
          <div class="fl head-label-area">活动名称</div>
          <Input class="fl" v-model="keywords" placeholder="商家名称/手机号" clearable style="width:200px"></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">活动商家</div>
          <Input class="fl" v-model="keywords" placeholder="商家名称/手机号" clearable style="width:200px"></Input>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">发布时间</div>
          <DatePicker
            v-model="createDate"
            format="yyyy-MM-dd"
            type="daterange"
            placement="bottom-end"
            placeholder="起止日期"
            style="width: 200px"
          ></DatePicker>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">活动时间</div>
          <DatePicker
            v-model="activeDate"
            format="yyyy-MM-dd"
            type="daterange"
            placement="bottom-start"
            placeholder="起止日期"
            style="width: 200px"
          ></DatePicker>
        </div>
        <div class="fl search-item">
          <div class="fl head-label-area">发布状态</div>
          <Select v-model="state" style="width:200px" clearable>
            <Option
              v-for="item in stateList"
              :value="item.dicCode"
              :key="item.dicCode"
            >{{ item.dicName }}</Option>
          </Select>
        </div>
      </div>
      <div class="fr right-search-btn">
        <Button type="primary" style="margin:0 0 10px;" icon="ios-search" @click="search">查询</Button>
        <Button @click="reset" icon="ios-refresh">重置</Button>
      </div>
    </div>
    <div class="table-area">
      <Table :columns="columns" :data="dataList" size="small" :height="tableHeight">
        <template slot-scope="{ row }" slot="action">
          <a @click="check(row)" style="margin-right:10px">查看</a>
          <a @click="del(row)" style="margin-right:0px">删除</a>
        </template>
      </Table>
    </div>
    <div class="page-area" v-if="dataList.length">
      <Page
        :total="total"
        :current="current"
        :page-size="size"
        show-total
        @on-change="pageChage"
        @on-page-size-change="pageSizeChage"
      ></Page>
    </div>
    <!-- <div class="packageManagement" v-if="comboManagementShow">
      <comboManagement
        @back="comboManagementShow = false"
        :storeId="currentData.id"
        v-if="comboManagementShow"
      />
    </div>-->
  </div>
</template>

<script>

// import { getStoreList } from '@/api/businessManagement.js'


export default {
  components: {
  },
  data() {
    return {
      keywords: '',
      state: '',
      createDate: [],
      activeDate: [],
      stateList: [
        {
          dicCode: '1',
          dicName: "已发布"
        },
        {
          dicCode: '2',
          dicName: "未发布"
        }
      ],
      tableHeight: window.innerHeight - 330,
      total: 0,
      current: 1,
      size: 20,
      columns: [
        {
          title: '发布事件',
          key: 'creatTime'
        },
        {
          title: '商家名称',
          key: 'storeName',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '用户昵称',
          key: 'nikeName',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '活动时间',
          key: 'activeTime',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '活动商家',
          key: 'storeName',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '活动人数',
          key: 'num',
        },
        {
          title: '套餐价格',
          key: 'price',
        },
        {
          title: '报名人数',
          key: 'bmnum',
        },
        {
          title: '发布状态',
          key: 'state',
          ellipsis: true,
          tooltip: true,
        },
        {
          title: '操作',
          width: 130,
          slot: 'action',
        },
      ],
      dataList: [],
    };
  },
  created() {
    this.findPageDataList()
  },
  methods: {
    reset() {
      this.keywords = ''
      this.search()
    },
    search() {
      this.current = 1
      this.findPageDataList()
    },
    findPageDataList() {
      //   let params = { size: this.size, pageIndex: this.current }
      //   if (this.keywords) {
      //     params.keywords = this.keywords
      //   }
      //   getStoreList(params).then(res => {
      //     if (res.success) {
      //       this.dataList = res.result.list
      //       this.total = res.result.total
      //     }
      //   })
    },
    check(row) {
      console.log(row)
    },
    del(row) {
      this.$Modal.confirm({
        title: "确认删除？",
        onOk: () => {
          console.log(row)
          //   delStore({ id: row.id }).then(res => {
          //     if (res.success) {
          //       this.$Message.success('删除成功！')
          //       this.findPageDataList()
          //     }
          //   });
        },
      })
    },
    pageChage(page) {
      this.current = page
      this.findPageDataList()
    },
    pageSizeChage(size) {
      this.size = size
      this.findPageDataList()
    }
  }
}

</script>
<style lang='scss' scoped>
.colonelPublishing {
  .left-search {
    width: calc(100% - 100px);
    .search-item {
      margin-bottom: 10px;
    }
  }
  .right-search-btn {
    width: 100px;
    border-left: 1px solid #e5e6eb;
    padding-left: 20px;
  }
  .head-label-area {
    line-height: 36px;
    // width: 120px;
    margin-left: 10px;
    padding-right: 15px;
    text-align: right;
  }
}
</style>